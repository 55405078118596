@font-face {
	font-family: Helvetica;
	src: url(../fonts/HelveticaWorld-Regular.ttf);
}

@font-face {
	font-family: Helvetica;
	src: url(../fonts/HelveticaWorld-Bold.ttf);
	font-weight: bold;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: black;
}
body {
	margin: 0;
	padding: 0;
	overflow-y: scroll;
	overflow: overlay;
	box-sizing: border-box;
	background: black;
	font-family: 'Roboto';
}

a {
	transition: 0.2s;
	text-decoration: none;
	color: black;
	margin: 0;
	font-family: 'Roboto', sans-serif;
}
p,
span {
	font-size: 1.2rem;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
}

h1 {
	color: white;
	font-weight: bold;
	font-size: 8vw;
}

button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0px;
}

img {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
}

/* width */
::-webkit-scrollbar {
	width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border: 3px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 1rem;
	background-color: rgba(101, 101, 101, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	border: 3px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 1rem;
	background-color: rgba(35, 35, 35, 0.8);
}

::selection {
	color: #ffffff;
	background: #1abc9c;
}
